<template>
  <div 
    id="gallery"
  >
    <div
      v-if="empty&&!admin&&!loading"
      class="fill-height d-flex flex-column justify-center align-center"
    >
      <v-img
        max-width="150"
        max-height="150"
        src="/img/icons/gallery-icon.png"
      />
      <h3
        class="text-h5 grey--text text--darken-1 mt-8"
      >
        A galeria está vazia
      </h3>
      <span
        class="text-body-2 text--secondary mt-3"
      >
        Esta Campanha ainda não tem mídias. Assim que forem adicionadas nós te avisamos!
      </span>
    </div>
    <v-btn-toggle
      v-else-if="admin&&Object.keys(statuses).length>0"
      :value="view.status.value"
      active-class="active primary primary--text"
      :mandatory="updated"
      class="filter mb-6"
    >
      <v-btn
        v-for="(option, o) in statuses"
        :key="`status-filter-option-${option.value}`"
        :value="option.value"
        outlined
        class="option button px-4 text-overline"
        @click="view.status.value = option.value"
      >
        {{ option.text }}
        <v-badge
          v-if="filter[o].length"
          inline
          :color="view.status.value==option.value ? 'primary' : 'grey lighten-1'"
          :content="filter[o].length"
          class="small"
        />
      </v-btn>
    </v-btn-toggle>

    <v-row dense>
      <v-col
        v-for="(media, p) in list"
        :key="p"
        cols="12"
        sm="6"
        md="4"
      >
        <gallery-card 
          :media="media"
          :loading="loading"
          :autoplay="selected==null"
          :zoomed="false"
          :admin="admin"
          @toast="alert"
          @download="download"
          @update="update"
          @remove="remove"
          @open="open"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-model="view.zoom.toggle"
      fixed
      :max-width="view.zoom.maxWidth"
      content-class="zoom-container"
    >
      <v-window
        v-model="view.zoom.selected"
      >
        <v-window-item
          v-for="media in list"
          :key="`zoom-media-${media.id}`"
          :value="media.id"
          :ref="`zoom-media-${media.id}`"
          eager
        >
          <gallery-card
            :media="media"
            :loading="loading"
            :square="false"
            :admin="admin"
            max-height="90vh"
            @toast="alert"
            @download="download"
            @update="update"
            @remove="remove"
          />
        </v-window-item>
      </v-window>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            absolute
            top
            right
            color="grey lighten-3"
            class="btn nav close mt-14"
            v-bind="attrs"
            v-on="on"
            @click="open(false)"
          >
            <v-icon>{{ icons.close }}</v-icon>
          </v-btn>
        </template>
        <span>Fechar</span>
      </v-tooltip>
      <v-tooltip 
        v-if="!isSingleList"
        left
      >
        <template v-slot:activator="{ on, attrs } ">
          <v-btn
            fab
            small
            absolute
            right
            color="grey lighten-3"
            class="btn nav next"
            v-bind="attrs"
            v-on="on"
            @click="next"
          >
            <v-icon>{{ icons.next }}</v-icon>
          </v-btn>
        </template>
        <span>Próximo</span>
      </v-tooltip>
      <v-tooltip 
        v-if="!isSingleList"
        right
      >
        <template v-slot:activator="{ on, attrs } ">
          <v-btn
            fab
            small
            absolute
            left
            color="grey lighten-3"
            class="btn nav prev"
            v-bind="attrs"
            v-on="on"
            @click="next(false)"
          >
            <v-icon>{{ icons.prev }}</v-icon>
          </v-btn>
        </template>
        <span>Anterior</span>
      </v-tooltip>
    </v-dialog>
    <v-overlay 
      :value="!updated"
      color="white"
      absolute
      opacity=".8"
      class="loading-overlay text-center"
    >
      <loading class="loading mb-4" />
      <span class="d-block text-overline grey--text text--lighten-1">
        Carregando
      </span>
    </v-overlay>
  </div>
</template>

<style lang="scss">

  .zoom-container {
    position: relative;
  }

  .zoom-container .btn.nav {
    top: 50%;
    transform: translateY(-50%);
  }

  #gallery .filter .button.option.active {
    border-bottom-color: var(--primary) !important;
    font-weight: 900;
    &:before {
      opacity: .04;
    }
  }
  #gallery .filter .option {
    border-bottom: 2px solid rgba(0, 0, 0, 0.12) !important;
    background: transparent !important;
  }


</style>

<script>
  import services from '@/services'
  import { sync } from 'vuex-pathify'
  import { 
    mdiArrowRight,
    mdiArrowLeft,
    mdiClose,
    mdiCamera
  } from '@mdi/js'
  import { 
    create as createGallery, 
    list as getGallery,
    update as updateGallery, 
    remove as removeGallery
  } from '@/api/gallery';
  import axios from 'axios'
  const moment = require('moment');

  export default {
    name: 'DashboardGallery',

    props: {
      pages: {
        type: Object,
        default: () => {
          return {}
        }
      },
      user: {
        type: Object,
        default: () => {}
      },
      dataKey: {
        type: String,
        default: null
      },
      scope: {
        type: Number,
        default: 0
      },
      children: {
        type: Array,
        default: () => null
      },
      pageTitle: {
        type: String,
        default: () => 'GO'
      },
      stored: {
        type: Object,
        default: () => {}
      },
      period: {
        type: Array,
        default: () => null
      },
    },

    data: () => ({
      icons: {
        next: mdiArrowRight,
        prev: mdiArrowLeft,
        close: mdiClose
      },
      gallery: {},
      view: {
        zoom: {
          toggle: false,
          hover: false,
          selected: null,
          maxWidth: undefined,
          minHeight: '96vh'
        },
        status: {
          value: 'all',
          options: {
            all: {
              text: 'Todas',
              value: 'all',
            },
            starred: {
              text: 'Destacadas',
              value: 'starred',
              status: 2,
            },
            approved: {
              text: 'Selecionadas',
              value: 'approved',
              status: 1,
            },
            rejected: {
              text: 'Rejeitadas',
              value: 'rejected',
              status: 0,
            },
            pending: {
              text: 'Não avaliadas',
              value: 'pending',
              status: null,
            },
          }
        }
      },
      loading: false,
      updated: false
    }),

    computed: {
      toast: sync('app/toast'),

      empty () {
        return _.size(this.gallery)==0;
      },

      isSingleList () {
        return _.size(this.list)==2;
      },

      admin () {
        const roles = this.user.roles;
        return _.size(_.intersection([1,5], roles))>0;
      },

      statuses () {
        return _.pickBy(this.view.status.options, (o,s) => this.filter[s].length>0);
      },

      filter () {
        const gallery = _.values(this.gallery);
        const filter = _.mapValues(_.clone(this.view.status.options), option => {
          return _.has(option, 'status') ? _.filter(gallery, ['status', option.status]) : gallery;
        })
        return filter
      },

      list () {
        const filter = this.view.status.value;
        const list = this.filter[filter];
        
        let gallery = _.values(list);
        if (this.admin) {
          gallery.unshift({
            url: null,
            id: null,
            created: Infinity,
            status: null,
          })
        } 
        return _.orderBy(gallery, ['status', 'created'], ['desc', 'desc']);
      },

      selected () {
        const id = this.view.zoom.selected;
        const gallery = this.gallery;
        return _.has(gallery, id) ? gallery[id] : null;
      }
    },

    watch: {
      dataKey: {
        immediate: true,
        handler (k) {
          if (this.period!=null) {
            this.clearData();
            this.updateView();
          }
        }
      },
      statuses: {
        deep: true,
        handler (statuses) {
          const selected = this.view.status.value;
          if (this.filter[selected].length==0) {
            this.view.status.value = 'all';
          }
        }
      } 
    },

    methods: {
      ...services,
      getGallery,
      createGallery,
      updateGallery,
      removeGallery,

      updateView () {
        this.load();
      },

      clearData () {
        this.gallery = {};
      },

      load () {
        const campaign = this.scope;
        this.loading = true;
        const key = this.dataKey.toString();
        const store = this.getStoredData(key, 'gallery');
        if (!_.isNil(store)) {
          const updated = moment().diff(moment(store.timestamp), 'minutes')<5;
          console.log('stored', updated);
          this.updated = updated;
          this.loading = !updated;
          this.set(store.source);
          if (updated) return false;
        }
        this.getGallery(
          this.user.auth.token, 
          key, 
          campaign, 
          (key, data) => {
            if (key==this.dataKey.toString()) {
              this.set(_.clone(data));
              this.updated = true;
              this.storeData('gallery', data);
            }
          },
          (error) => {
            if (error.response.status!=404) {
              const msg = 'Aguardando resposta da nuvem...';
              setTimeout(($) => {
                this.handleError(error, msg, [
                  true,
                  msg,
                  7000,
                  false
                ]);
                setTimeout(($) => $.load(), 5000, $);
              }, 5000, this);
            }
          },
          () => {
            this.loading = false;
          }
        )
      },

      set (data) {
        if (!this.admin) {
          data = _.pickBy(data, d => _.indexOf([1,2], d.status)>=0);
        }
        this.gallery = data;
      },

      storeData (dimension, source) {
        console.log('store', dimension);
        const key = this.dataKey.toString();
        source = _.clone(source);
        this.$emit('store-data', {
          key, 
          dimension,
          source
        })
      },

      getStoredData (key, dimension) {
        return _.has(this.stored, dimension) && this.stored[dimension].key==key ? this.stored[dimension] : null;
      },

      update (id, items, n) {
        n = _.isNil(n) ? 0 : n;
        const multiple = _.isArray(items) ? _.size(items) : false;
        let message = multiple ? n+'/'+multiple+' mídias salvas...' : 'Mídia salva com sucesso'
        this.loading = true;
        const action = _.isNil(id) ? 'create' : 'update';
        if (!multiple&&!_.has(items, 'file')) {
          const msgs = ['Mídia reprovada', 'Mídia aprovada', 'Mídia destacada!']
          message = msgs[items.status];
        }
        console.log('update', items);

        this[action+'Gallery'](
          this.user.auth.token, 
          { ...(multiple ? items[n] : items), campaign_id: parseInt(this.scope) },
          (data) => {
            // success
            const before = _.isNil(id) ? {} : this.gallery[data.id];
            this.$set(this.gallery, data.id, Object.assign(before, _.clone(data)));
            this.$nextTick(() => {
              this.storeData('gallery', this.gallery)
            });
            if (multiple) {
              n = n+1;
              message = multiple==n ? 'Galeria salva com sucesso' : n+'/'+multiple+' midias salvas...';
              if (multiple>n) this.update(id, items, n);
            }
            this.toggleToast(
              true,
              message,
              multiple ? 0 : 7000,
              multiple&&multiple==n
            );
          },
          (error) => {
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              'Ocorreu um erro inesperado ao salvar a mídia. Tente novamente, por favor.' : 
              _.has(error.response.data, 'message') ? error.response.data.message : 'Ocorreu um erro inesperado. Tente novamente, por favor.';
            this.toggleToast(
              true,
              msg,
              15000,
              false
            );
          },
          () => {
            this.loading = false;
          },
        )
      },
      
      remove (id) {
        this.loading = true
        this.removeGallery(
          this.user.auth.token, 
          id, 
          () => {
            // success
            this.toggleToast(
              true,
              'Mídia apagada com sucesso',
              7000,
              false
            );
            if (this.view.zoom.toggle) this.next();
            this.$nextTick(() => {
              this.$delete(this.gallery, id);
              this.$nextTick(() => {
                this.storeData('gallery', this.gallery)
              });
            })
          },
          (error) => {
            const msg = _.has(error.response, 'status') && error.response.status == 500 ? 
              'Ocorreu um erro inesperado ao apagar a mídia. Tente novamente, por favor.' : 
              _.has(error.response.data, 'message') ? error.response.data.message : 'Ocorreu um erro inesperado. Tente novamente, por favor.';
            this.toggleToast(
              true,
              msg,
              15000,
              false
            );
          },
          () => {
            this.loading = false;
          }
        )
      },

      open (b, id) {
        this.view.zoom.toggle = b;
        if (b) {
          this.view.zoom.selected = _.isNil(id) ? null : id;
          this.resize(id);
        }else{
          setTimeout(($, id) => {
            $.view.zoom.selected = _.isNil(id) ? null : id;
            this.resize(id);
          }, 500, this, id)
        }
      },
      next (b) {
        b = _.isNil(b) ? true : b;
        const gallery = this.list;
        const i = _.findIndex(gallery, ['id', this.view.zoom.selected]);
        let n = b ? (i+1>gallery.length-1 ? 1 : i+1) : (i-1==0 ? gallery.length-1 : i-1);
        const id = this.list[n].id;
        this.view.zoom.selected = id;
        console.log('next: '+this.list[n].url);
        this.resize(id);
      },
      resize (id) {
        if (!this.view.zoom.toggle||this.view.zoom.selected!=id) return;
        let width = null;
        let sizer = null;
        let container = null;
        const viewport = { w: window.innerWidth, h: innerHeight }
        if (_.has(this.$refs, 'zoom-media-'+id)){
          container = this.$refs['zoom-media-'+id][0];
          // const img = this.$refs['image'];
          sizer = container.$el.querySelector('.v-responsive__sizer');
          if (sizer==null) {
            sizer = container.$el.querySelector('.video');
          }
        }
        if (sizer==null||sizer.clientWidth==0) {
          setTimeout(($, id) => {
            $.resize(id)
          }, 100, this, id);
        }else{
          const ar = sizer.clientWidth/sizer.clientHeight;
          console.log('sizer', id, ar);
          if (ar<1) {
            width = _.round(container.$el.clientHeight*ar);
            console.log('portrait img resized', id, width);
          }else {
            width = viewport.w * .9;
            console.log('landscape img resized', id, width);
            if (width/ar>viewport.h*.9) {
              width = _.round(viewport.h*.9*ar);
              console.log('landscape img max', id, width);
            }
          }
          // this.view.zoom.minHeight = undefined
        }
        console.log('resize gallery dialog', width);
        if (width!=null) this.view.zoom.maxWidth = width;
      },

      alert (msg) {
        const toggle = msg!==false;
        this.toggleToast(
          toggle,
          toggle ? msg : '',
          7000,
          false
        );
      },

      download (media) {
        const url = media.url;
        const id = media.id;
        const format = _.last(_.split(url, '.'));
        axios.get(url, { responseType: 'blob' }).then((response) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `${this.title}-${id}.${format}`);
          document.body.appendChild(link);
          link.click();
          link.remove();
        });
      }
    },

    mounted () {
      this.updateView()
    },
    
    components: {
      GalleryCard: () => import('@/components/GalleryCard'),
      Loading: () => import('@/components/IconLoading'),
    }
  }
</script>
