import _ from 'lodash';

function list (token, key, campaign, resolve, reject, complete) {
  console.log('gallery.list... ', key);

  this.$api.SSP
    .get('/dashboard/gallery/?campaign_id='+campaign+'&format=json', {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('dashboard.gallery => ', response.data);

      const data = _.keyBy(_.map(response.data, d => {
        return {
          id: d.id_campaign_gallery,
          campaign: d.id_campanha,
          created: d.dt_created,
          updated: d.dt_updated,
          status: d.status,
          url: d.url,
        };
      }), 'id');

      resolve(key, data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('dashboard.gallery');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      // callback function
      if (this.isValid(complete)) complete();
    })
};

function create (token, data, resolve, reject, complete) {
  const form = new FormData()
  _.each(data, (v,k) => {
    form.append(k,v);
  })
  this.$api.SSP
    .post('/dashboard/gallery/', form, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      },
    })
    .then(response => {
      console.log('gallery.create => ', response.data);

      const data = {
        id: response.data.id_campaign_gallery,
        campaign: response.data.id_campanha,
        created: response.data.dt_created,
        updated: response.data.dt_updated,
        status: response.data.status,
        url: response.data.url,
      };

      resolve(data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('gallery.create');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      if (this.isValid(complete)) complete();
    })
}

function update (token, data, resolve, reject, complete) {
  const form = new FormData()
  _.each(data, (v,k) => {
    form.append(k,v);
  })
  this.$api.SSP
    .put(`/dashboard/gallery/`, form, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-type': 'multipart/form-data'
      },
    })
    .then(response => {
      console.log('gallery.update => ', response.data);

      const data = {
        id: response.data.id_campaign_gallery,
        campaign: response.data.id_campanha,
        created: response.data.dt_created,
        updated: response.data.dt_updated,
        status: response.data.status,
        url: response.data.url,
      };

      resolve(data);
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('gallery.update');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      if (this.isValid(complete)) complete();
    })
}

function remove (token, id, resolve, reject, complete) {
  this.$api.SSP
    .delete(`/dashboard/gallery/?id=${id}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
    .then(response => {
      console.log('gallery.remove => ', response.data);

      resolve();
    })
    .catch(error => {
      if(_.has(error.response, 'status') && error.response.status==401){
        this.getout('gallery.remove');
        this.handleError(error, 'Sua sessão expirou...', true);
      }else{
        this.handleError(error);
        if (this.isValid(reject)) reject(error);
      }
    })
    .finally(() => {
      if (this.isValid(complete)) complete();
    })
}

export {
  list,
  create,
  update,
  remove,
}